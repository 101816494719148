var topBtn = document.getElementById("topButton");
var visible = false;
if (topBtn) {
    window.addEventListener('scroll', (event) => {        
        if((document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) && window.innerWidth > 1025 ) {

            topBtn.classList.remove("hidden");
            topBtn.classList.add("fixed");
            visible = true;
            topBtn.classList.remove("hide");
    
        } else {
            visible = false;
            topBtn.classList.add("hide");
            topBtn.addEventListener("animationend", function() {
    
                visible === true ? '' : topBtn.classList.add("hidden");
            
            }); 
            
        }   
    });
    
}
